<template>
  <div class="page-content">
    <div class="container">
      <div class="ae add-employee red-theme">
        <div class="row">
          <div class="col-12 col-md-8">
            <h3 class="ae__title">Добавить сотрудника</h3>
            <p class="ae__desc">
              Редактирование данные сотрудников,
              отключить от работы или полность удалить профиль
            </p>
          </div>
        </div>
        <div>
          <v-form v-model="valid" ref="form">
            <div class="row">
              <template v-for="(item, i) in formData">
                <div
                  class="col-12 col-md-4 mb--sm"
                  :key="`category-${i}`"
                >
                  <Select
                    label="Выберите категорию услуги"
                    :items="categories"
                    item-value="id"
                    item-text="name"
                    v-model="item.category"
                    required
                  />
                </div>

                <div
                  class="col-12 col-md-4 mb--sm"
                  :key="`responsibility-${i}`"
                >
                  <Select
                    label="Выберите категорию услуги"
                    :items="normalizeResponsibilities(item.category)"
                    item-value="id"
                    item-text="name"
                    v-model="item.responsibility"
                    :disabled="!item.category"
                    required
                  />
                </div>

                <div
                  class="col-12 col-md-4 mb--sm"
                  :key="`phone-${i}`"
                >
                  <PhoneInput
                    label="Телефон*"
                    v-model="item.phone"
                    required
                  />
                </div>
              </template>
              <div
                class="col-12 col-md-6 mb--sm button-group"
              >
                <button
                  class="link"
                  type="button"
                  @click="addFieldGroup"
                >+ добавить</button>
                <button
                  v-if="formData.length > 1"
                  type="button"
                  class="link"
                  @click="removeFieldGroup"
                >удалить</button>
              </div>
            </div>
          </v-form>
        </div>
        <div class="ae-btn-wrapp">

          <router-link
            :to="{ name: $routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS }"
            class="btn btn--border btn--fs"
          >Вернуться к заявкам</router-link>

          <button
            class="btn btn--prime btn--fs"
            @click="submit"
          >

            <template v-if="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </template>

            <template v-else>Отправить приглашение</template>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import Select from "@component/UI/Form/Select";
import PhoneInput from "@component/UI/Form/PhoneInput";

export default {
  name: "AddEmployee",
  components: {
    Select,
    PhoneInput,
  },
  data() {
    return {
      loading: false,
      valid: false,
      formData: [
        {
          category: '',
          phone: '',
          responsibility: ''
        }
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      categories: state => state.enums.categories
    }),
    ...mapGetters('enums', ['services']),
    companyId() {
      return this.user?.company?.id;
    }
  },
  methods: {
    normalizeResponsibilities(category) {
      return this.services.filter(service => service.category === category);
    },
    removeFieldGroup() {
      this.formData.splice(-1, 1);
    },
    addFieldGroup() {
      this.formData.push({
        category: '',
        phone: '',
        responsibility: ''
      });
    },
    submit() {
      try {
        this.loading = true;
        let formData = this.formData.map(({ phone, category, responsibility }) => {
          return {
            mobile: phone,
            from_company: this.companyId,
            responsibility,
            category
          };
        });

        RequestManager.Invite.sendSMS(formData).then(() => {
          let title = formData.length > 1 ? 'Приглашения отправлены' : 'Приглашение отправлено';
          this.$modal.show('Accept', { title })
            .then(() => {
              this.formData = [{ category: '', phone: '', responsibility: ''}];
              this.$refs.form.reset();
            });
        })

      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.button-group {
  margin: 0 -10px;
  button {
    margin: 0 10px;
    color: var(--color-prime);
  }
}

.add-employee {
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 47px 26px;
}

.ae__title {
  font-size: 25px;
  color: var(--color-prime);
  margin-bottom: 12px;
}

.ae__desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;
}

.ae-btn-wrapp {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @include respond-to('md') {
    flex-wrap: nowrap;
    margin-top: 40px;
  }
}

.btn {
  max-width: 343px;
  margin-bottom: 15px;
}
</style>
